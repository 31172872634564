* {
  -webkit-tap-highlight-color: transparent;
  font-display: swap;
}

.simple-offcanvas {
  background: #ff5757 !important;
  width: 80% !important;
}
.styles-module_header__1aDvk {
  display: none !important;
}

.mentions {
  width: 100% !important;
  height: 300px !important;
}
.mentions__input {
  border: 1px solid #c1c1c1 !important;
  border-radius: 8px !important;
  width: 100% !important;
  height: 300px !important;
}
.mentionCard {
  background: var(--surface-card);
  padding: 2rem;
  border-radius: 10px;
  margin-bottom: 1rem;
}
