@font-face {
  font-family: "icomoon";
  src: url("../components/IcoMoonIcons/icomoon.ttf");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-hamburger:before {
  content: "\e901";
}
.icon-backIcon:before {
  content: "\e900";
}
.icon-down-chev:before {
  content: "\e902";
}
.icon-left-chev:before {
  content: "\e903";
}
.icon-right-chev:before {
  content: "\e904";
}
.icon-up-chev:before {
  content: "\e905";
}
.icon-search:before {
  content: "\e906";
}
.icon-left-arrow:before {
  content: "\e907";
}
.icon-right-arrow:before {
  content: "\e908";
}
.icon-plus:before {
  content: "\e909";
}
.icon-cross:before {
  content: "\e90b";
}
.icon-cursor:before {
  content: "\e90a";
}
